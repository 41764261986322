<template>
  <el-dialog :visible.sync="visible" title="批量新增员工" width="fit-content">
    <el-upload action="#" :limit="limitNum" :drag="true" :auto-upload="true" accept=".xlsx, .xlsm, .xls"
      :before-upload="beforeUploadHandler" :on-exceed="exceedFile">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">只能上传xlsx文件，且不超过10M</div>
    </el-upload>
  </el-dialog>
</template>

<script>
import { read, utils } from "xlsx";
import { nylx } from "@/assets/json/index.js";

export default {
  data() {
    return {
      dataForm: [
        "did",
        "tid",
        "number",
        "clsbh",
        "fdjh",
        "gzsj",
        "tcrq",
        "sccj",
        "chexing",
        "nylx",
        "xh",
        "wxcc",
        "zws",
        "syzz",
        "pwbz",
        "yxlx",
        "clpp",
        "cldw",
        "chuchangDate",
        "syxz",
        "bfnx",
        "clpl",
        "ssgs",
        "xszh",
        "xszfzDate",
        "xszsyDate",
        "dlyszh",
        "dlyszfzDate",
        "dlyszsyDate",
        "gpspp",
        "gpsxh",
        "spjkpp",
        "spjkxh"
      ],
      visible: false,
      limitNum: 1,
    };
  },
  methods: {
    // 组件初始化
    init() {
      this.visible = true;
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(
        `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length
        } 个`
      );
    },
    // 上传文件之前的钩子
    beforeUploadHandler(file) {
      const _this = this;
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          // 切换为新的调用方式
          const workbook = read(data, {
            type: "binary",
          });

          // 取第一张表
          const wsname = workbook.SheetNames[0];
          // 切换为新的调用方式 生成json表格内容

          const ws = utils.sheet_to_json(workbook.Sheets[wsname], {
            header: this.dataForm,
            range: 1,
          });



          // 检查格式是否正确
          let error_arr = [];
          ws.forEach((item) => {
            if (!item.did) {
              error_arr.push("部门id不能为空");
            }
            if (!item.tid) {
              error_arr.push("班组id不能为空");
            }
            if (!item.number) {
              error_arr.push("车牌号不能为空");
            }
            if (!nylx[item.nylx]) {
              error_arr.push("能源类型填写错误");
            }
          });
          if (error_arr?.length > 0) {
            this.$message.error(error_arr[0]);
            return;
          }

          // 后续为自己对ws数据的处理

          let result = ws.map((ele) => {
            // 需要处理时间格式的数据键名
            // let waitFormateDateKeyName = [
            //   "gzsj",
            //   "tcrq",
            //   "chuchangDate",
            //   "xszfzDate",
            //   "xszsyDate",
            //   "dlyszfzDate",
            //   "dlyszsyDate",
            // ];

            return {
              ...ele,
              gzsj: this.formatExcelDate(ele.gzsj),
              tcrq: this.formatExcelDate(ele.tcrq),
              chuchangDate: this.formatExcelDate(ele.chuchangDate),
              xszfzDate: this.formatExcelDate(ele.xszfzDate),
              xszsyDate: this.formatExcelDate(ele.xszsyDate),
              dlyszfzDate: this.formatExcelDate(ele.dlyszfzDate),
              dlyszsyDate: this.formatExcelDate(ele.dlyszsyDate),
            };
          });
          // console.log(result, "result");
          // this.$message.warning("正在紧急开发中");
          // return;
          this.$http.car
            .batchSave({
              carList: result,
            })
            .then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
                duration: 1000,
                onClose: () => {
                  _this.visible = false;
                  _this.$nextTick(() => {
                    _this.$emit("refreshDataList");
                  });
                },
              });
            })
            .catch((err) => {
              console.log(err, "err");
            });
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
      //
      return false;
    },
    // 格式化excel返回的日期
    formatExcelDate(numb, format = "-") {
      if (!numb) return "";
      const time = new Date(
        (numb - 25567) * 24 * 3600000 -
        5 * 60 * 1000 -
        43 * 1000 -
        24 * 3600000 -
        8 * 3600000
      );
      time.setYear(time.getFullYear());
      const year = time.getFullYear() + "";
      const month = time.getMonth() + 1 + "";
      const date = time.getDate() + "";
      if (format && format.length === 1) {
        return year + format + month + format + date + " 00:00:00";
      }
      return (
        year +
        (month < 10 ? "0" + month : month) +
        (date < 10 ? "0" + date : date) +
        " 00:00:00"
      );
    }
  },
};
</script>